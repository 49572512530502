// JavaScript Document
jQuery(function ($) {
    /*------------------
    scroll
  --------------------*/
    if (window.innerWidth >= 768) {
        var headerHight = 80;
    } else {
        var headerHight = 50;
    }
    var speed = 400;

    $(document).on("click", 'a[href^="#"]:not(.inline)', function () {
        var href = $(this).attr("href");
        var target = $(href === "#" || href === "#" ? "html" : href);
        var position = target.offset().top - headerHight;
        $("html, body").animate({ scrollTop: position }, speed, "swing");
        return false;
    });

    var url = $(location).attr("href");
    if (url.indexOf("?id=") == -1) {
    } else {
        var url_sp = url.split("?id=");
        var hash = "#" + url_sp[url_sp.length - 1];
        var target2 = $(hash);
        if (target2[0]) {
            var position2 = target2.offset().top - headerHight;
            $("html, body").animate({ scrollTop: position2 }, speed, "swing");
        }
    }

    var img_normal = "logo.";
    var img_sticky = "logo_sticky.";
    var thisItem = $("#logo").find("img");

    if (window.matchMedia("(max-width:999px)").matches) {
        if (thisItem.attr("src") !== undefined) {
            thisItem.attr("src", thisItem.attr("src").replace(img_normal, img_sticky));
        }
        $(window).on("scroll", function () {
            var scroll = $(this).scrollTop();
            if (scroll > 500) {
                $(".header-main").addClass("sticky");
                $("main").addClass("is-scroll");
            } else {
                $(".header-main").removeClass("sticky");
                $("main").removeClass("is-scroll");
            }
        });
    } else {
        var y = window.scrollY;
        if (y < 500) {
            if (thisItem.attr("src") !== undefined) {
                thisItem.attr("src", thisItem.attr("src").replace(img_sticky, img_normal));
            }
        } else {
            if (thisItem.attr("src") !== undefined) {
                thisItem.attr("src", thisItem.attr("src").replace(img_normal, img_sticky));
            }
        }

        $(window).on("scroll", function () {
            var scroll = $(this).scrollTop();
            if (scroll > 500) {
                if (thisItem.attr("src") !== undefined) {
                    thisItem.attr("src", thisItem.attr("src").replace(img_normal, img_sticky));
                }
                $(".header-main").addClass("sticky");
                $("main").addClass("is-scroll");
            } else {
                if (thisItem.attr("src") !== undefined) {
                    thisItem.attr("src", thisItem.attr("src").replace(img_sticky, img_normal));
                }
                $(".header-main").removeClass("sticky");
                $("main").removeClass("is-scroll");
            }
        });
    }
    const mediaQueryList = window.matchMedia('(min-width: 1000px)');
    mediaQueryList.addEventListener("change", listener);
    listener(mediaQueryList);
    function listener(event) {
        if (event.matches) {
            if (y < 500) {
                if (thisItem.attr("src") !== undefined) {
                    thisItem.attr("src", thisItem.attr("src").replace(img_sticky, img_normal));
                }
            } else {
                if (thisItem.attr("src") !== undefined) {
                    thisItem.attr("src", thisItem.attr("src").replace(img_normal, img_sticky));
                }
            }
            $(window).on("scroll", function () {
                var scroll = $(this).scrollTop();
                if (scroll > 500) {
                    if (thisItem.attr("src") !== undefined) {
                        thisItem.attr("src", thisItem.attr("src").replace(img_normal, img_sticky));
                    }
                    $(".header-main").addClass("sticky");
                    $("main").addClass("is-scroll");
                } else {
                    if (thisItem.attr("src") !== undefined) {
                        thisItem.attr("src", thisItem.attr("src").replace(img_sticky, img_normal));
                    }
                    $(".header-main").removeClass("sticky");
                    $("main").removeClass("is-scroll");
                }
            });
        } else {
            // SP
            if (thisItem.attr("src") !== undefined) {
                thisItem.attr("src", thisItem.attr("src").replace(img_normal, img_sticky));
            }
            $(window).on("scroll", function () {
                var scroll = $(this).scrollTop();
                if (thisItem.attr("src") !== undefined) {
                    thisItem.attr("src", thisItem.attr("src").replace(img_normal, img_sticky));
                }
                if (scroll > 500) {
                    $(".header-main").addClass("sticky");
                    $("main").addClass("is-scroll");
                } else {
                    $(".header-main").removeClass("sticky");
                    $("main").removeClass("is-scroll");
                }
            });
        }
    }


    //スクロールアニメ
    $(window).scroll(function () {
        $(".anim").each(function () {
            var imgPos = $(this).offset().top;
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            if (scroll > imgPos - windowHeight + windowHeight / 6) {
                if (!$(this).hasClass("is-act")) {
                    $(this).addClass("is-act");
                }
            }
        });
        $(".anim-list").each(function () {
            var elemPos = $(this).offset().top;
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            if (scroll > elemPos - windowHeight + windowHeight / 6) {
                var i = 0;
                var $list = $(this).children();
                setInterval(function () {
                    $list.eq(i).addClass("is-act");
                    i++;
                    if (i >= $list.length) i = 0;
                }, 200);
            }
        });
    });

    $("#menu-btn").on("click", function () {
        $(this).toggleClass("open");
        if ($(this).hasClass("open")) {
            $("#g-navi").addClass("open");
        } else {
            $("#g-navi").removeClass("open");
        }
    });
    $("#g-navi a").on("click", function () {
        $("#menu-btn").removeClass("open");
        $("#g-navi").removeClass("open");
    });

    var ua = navigator.userAgent;
    if (ua.indexOf("iPhone") === -1 && ua.indexOf("Android") === -1) {
        jQuery('a[href^="tel:"]')
            .css("cursor", "default")
            .on("click", function (e) {
                e.preventDefault();
            });
        jQuery(".btn-tel")
            .css("cursor", "default")
            .on("click", function (e) {
                e.preventDefault();
            });
    }
    if ($('#agreement').length) {
        const agreeCheckbox = document.getElementById("agreement");
        // 送信ボタンを取得
        const submitBtn = document.getElementById("btn-submit");

        // チェックボックスをクリックした時
        agreeCheckbox.addEventListener("click", () => {
            // チェックされている場合
            if (agreeCheckbox.checked === true) {
                submitBtn.disabled = false; // disabledを外す
            }
            // チェックされていない場合
            else {
                submitBtn.disabled = true; // disabledを付与
            }
        });
    }
});


